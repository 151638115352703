import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "../styles/index.css";
import "animate.css";

import { Container, Col, Row, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import Menu from "../pages/menu";
import RightColumn from "../pages/rightcolumn";
import Header from "../pages/header";


function Index() {
  const [date, setDate] = useState(null);
  const [message, setMessage] = useState(null);
  const [displayStatus, setDisplay] = useState(0.5);
  const [displayStatusFlying, setDisplayFlying] = useState(false);


  useEffect(() => {
    async function getDate() {
      const res = await fetch("/api/date");
      const newDate = await res.text();
      setDate(newDate);
    }
    getDate();
  }, []);

  const handleTranScribe = () => {
    const textContent = document.querySelector("#textAreaContent").value;
    if(!textContent){
      setMessage(() => "Please provide some text!");
      return;
    }    
    setMessage(() => "English text is now transcribed!");
     
  };

  function changeBackground(e) {
    setDisplayFlying(false) 
    setDisplay(1)    
  }

  function changeBackgroundLeave(e) {
    setDisplay(0.5)
    setDisplayFlying(true)    
  }

  
  return (
    <main>
      <Helmet>
        <title>Bảng phiên âm quốc tế online</title>
      </Helmet>
      <Container fluid>
        <Row className="justify-content-md-center">
          <Col className="" lg={1} md={1} xs={0}>
            <Menu />
          </Col>
          <Col lg={1} md={1} xs={0}></Col>
          <Col lg={8} md={8} xs={11}>
            <Header />
            <div
              className="fontrem30 font-futura"
              style={{ maxWidth: "85%", margin: "auto" }}
            >
              <h3 className="color__main-dark-red font-futura introStyle">
                English words to phonetics
              </h3>

              <Form className="fontrem20">
                <Form.Group controlId="exampleForm.ControlTextarea1">                  
                  <Form.Control
                    placeholder="Please nhập text..."
                    id="textAreaContent"
                    as="textarea"
                    rows={8}
                  />
                </Form.Group>
              </Form>
              <p>{message}</p>
              <p 
              className = {`animate__animated animate__swing ${displayStatusFlying ? 'animate__fadeOutUp': '' }`}
              style = {{opacity: displayStatus,paddingLeft: "30%"}}
              >웃♫♫♫♫♫♫♫유</p>
              <h3 id="clickButton" 
              onClick={handleTranScribe}
              onMouseOver={changeBackground}
              onMouseLeave = {changeBackgroundLeave}
              >
                TRANSCIBE NOW
              </h3>
            </div>
          </Col>
          <Col lg={2} md={2} xs={0}>
            <RightColumn index="0" />
          </Col>
        </Row>
      </Container>
    </main>
  );
}

export default Index;
